<div class="supp-selector-div">
  <input #input (focus)="selectText()" [(ngModel)]="value" [disabled]="this.params.data._tags.includes('disabled')"
         class="input"/>

  <div (click)="toggleDropdown()" class="toggle-button"> Options
    <app-icon-chevron-down></app-icon-chevron-down>
  </div>

  <div #optionsList *ngIf="showOptions" [ngClass]="{'options-list': true, 'above': displayAbove}">
    <div class="scrollable-options">
      <div *ngFor="let option of tags; trackBy: trackByFunction ">
        <label>
          <input
            (change)="toggleSelection(option)"
            [checked]="selectedValues.includes(option)"
            [disabled]="params.data?._tags?.includes('disabled')"
            type="checkbox"/>
          {{ option }}
        </label>
      </div>
    </div>
    <button (click)="onConfirmButtonClick()" class="buttons" type="button">OK</button>
    <button (click)="onCancelButtonClick()" class="buttons" type="button">Cancel</button>
  </div>

  <!-- Undo changes button icon -->
  <app-display-icon
    (click)="onUndoChangesClick()"
    *ngIf="params.data.originalValue[params.colDef.field].value !== selectedValues"
    [icon]="icons.backSpaceOutline"
    class="backspace">
  </app-display-icon>
</div>
