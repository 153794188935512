import {Injectable} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection, AngularFirestoreDocument,
  CollectionReference,
  DocumentData,
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from '@angular/fire/compat/firestore';
import {FieldPath} from 'firebase/firestore';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {BehaviorSubject, forkJoin, Observable, Subscriber, Subscription} from 'rxjs';
import {FireAuthService} from './fire-auth.service';
import {map, mergeMap, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AlertController, ModalController} from '@ionic/angular';

import {arrayRemove, arrayUnion, deleteField, documentId, serverTimestamp} from '@angular/fire/firestore';

import * as firebase from 'firebase/compat';

import {
  AOSettings,
  ApiLogObj,
  ApiLogObj2,
  castStockItem,
  Colleague,
  DepSales,
  DepSales2,
  DepSalesHistory,
  Feature,
  FormPost,
  FormPostPoll,
  Message,
  MsgType,
  PriceBand,
  RawStockItem,
  sItemKeyToInt,
  StockItem,
  StockItemUpdateObj,
  StockValChangeCheckConfig,
  StockValuesChangeCheckResult,
  StoreInfo,
  Supplier,
} from '../models-old/datastructures';
import {ForceReloadService} from './force-reload.service';
import {pageRules, RuleHumanID} from '../models-old/utils-old/rule-structure';
import {dateDelta} from '../functions-old/date-functions';
import {
  AOScheduleElement,
  AutoOrder,
  AutoOrderCollection,
  AutoOrderItem,
  AutoOrderPreparedInfo,
} from '../models-old/auto-ordering/ao-datastructures';
import {StockFunctions} from '../functions-old/stock-functions';
import {
  StockValChangeFlagsComponent,
} from '../../shared-modules/shared-module/components/stock-val-change-flags/stock-val-change-flags.component';
import {IStore} from '../../shared/shared-models/store/store';
import {StoreObject} from '../models-old/store/store-object';
import {IPriceBand, IPriceBandPerStore} from '../../shared/shared-models/price-banding/price-band';
import {IFirebaseQuery} from '../../shared/shared-models/firebase/firebase-queries';
import {castObjectToType} from '../../shared/shared-utils/object/object.utils';
import {IDepartment, ISubDepartment} from '../../shared/shared-models/stock/departments';
import {IDefaultGroupAccessDocument, IGroupAccess} from '../../shared/shared-models/user-access/group-access';
import {IUser} from '../../shared/shared-models/user-access/user';
import {IUserAccess} from '../../shared/shared-models/user-access/user-access';
import {
  CollectionStoresSettingsService,
} from '../../shared/shared-services/firebase/collection-stores-settings.service';
import {
  path_stores_storeId_settings_ao_supp_emails,
  path_stores_storeId_settings_price_bands,
  path_stores_storeId_settings_stock_edit_thresholds,
} from '../../shared/shared-services/database-paths';
import {IAutoOrderOrderPreparedInfoDetailsFirestore} from '../../shared/shared-models/auto-ordering/auto-order-order';

export const DOCUMENT_ID = documentId;
export const DELETE_FILED = deleteField;
export const SERVER_TIMESTAMP = serverTimestamp;
export const ARRAY_UNION = arrayUnion;
export const ARRAY_REMOVE = arrayRemove;
// TODO: Gross. Find a better way

export type Timestamp = firebase.default.firestore.Timestamp;

export interface FbQuery {
  q: 'where' | 'orderBy' | 'limit' | 'startAt' | 'startAfter' | 'endAt' | 'endBefore';
  p: any[];
}

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private userId: string;
  private userAccess: IUserAccess = {} as IUserAccess;
  private sVChangeCheckConfigs: { [storeId: string]: StockValChangeCheckConfig } = {};
  private lastSeenRefreshTask: NodeJS.Timeout;

  private readonly userObjSubject: BehaviorSubject<IUser>;
  // Todo: Convert this to IStore
  private readonly storesMapObs: BehaviorSubject<{ stores: { [storeId: string]: StoreInfo }; order: string[] }>;
  private readonly storeDataSubs: { [storeId: string]: { [doc: string]: Observable<any> } } = {};
  private readonly colleaguesBS: BehaviorSubject<{
    users: { [userId: string]: Colleague };
    storesUsers: { [storeId: string]: string[] };
  }>;

  constructor(
    private fireAuthService: FireAuthService,
    private forceReloadService: ForceReloadService,
    private angularFirestore: AngularFirestore,
    private angularFireStorage: AngularFireStorage,
    private router: Router,
    private modalController: ModalController,
    private alertControl: AlertController,
    private collectionStoresSettingsService: CollectionStoresSettingsService,
  ) {
    let userAccessSub: Subscription;
    this.storesMapObs = new BehaviorSubject<
      { stores: { [storeId: string]: StoreInfo }; order: string[] }
    >({stores: {}, order: []});
    this.colleaguesBS = new BehaviorSubject({users: {}, storesUsers: {}});

    this.userObjSubject = new BehaviorSubject<IUser>(null);
    const colleagues: { [userId: string]: Colleague } = {};

    this.fireAuthService.userIdSub.subscribe(userId => {

      if (userId) {

        if (this.userId && this.userId !== userId) {
          userAccessSub.unsubscribe();
        }

        if (this.userId !== userId) {

          if (this.userId) {
            this.router.navigate(['home']).then();
          }
          this.userId = userId;

          userAccessSub = this.angularFirestore.doc(`user_access/${userId}`).valueChanges().subscribe(data => {
            this.userAccess = data as IUserAccess;

            if (this.userAccess?.storeList && this.userAccess?.storeList.length > 0) {
              void this.angularFirestore.collection('stores', (ref) => ref.where(DOCUMENT_ID(), 'in', this.userAccess.storeList))
                .get().toPromise().then(qs => {
                    const storesMap = {};
                    const storesOrder: string[] = qs.docs.map((doc: QueryDocumentSnapshot<unknown>) => {
                      const storeId = doc.id;
                      storesMap[storeId] = doc.data() as StoreInfo;

                      // TODO: TEMPORARY DEFAULT
                      void this.getStoreEditFlagConfig(storeId).then();

                      return storeId;
                    });
                    storesOrder.sort((a: string, b: string) => storesMap[a].name < storesMap[b].name ? -1 : 1);
                    this.storesMapObs.next({stores: storesMap, order: storesOrder});
                  },
                );

              // // Get store info
              // const storesMap = {};
              // let storesOrder: string[] = [];
              //
              // for (const storeId of Object.keys(this.userAccess.stores)) {
              //   this.af.doc(`stores/${storeId}`).get().toPromise().then(doc => {
              //     storesMap[storeId] = doc.data() as StoreInfo;
              //     storesOrder = storesOrder.concat(storeId);
              //     storesOrder.sort((a, b) => storesMap[a].name<storesMap[b].name?-1:1);
              //     // TODO: Is this really the best way to do it? Stores trickle through one by one?
              //     this.storesMapObs.next({stores: storesMap, order: storesOrder});
              //   });
              // }

              // Get colleagues
              // ---------------------------
              // ---------------------------

              // start online indication
              this.updateLastSeen();

              if (this.lastSeenRefreshTask) {
                clearInterval(this.lastSeenRefreshTask);
              }
              this.lastSeenRefreshTask = setInterval(() => {
                this.updateLastSeen();
              }, 120000);
            }
          }, error => {
            console.error(`\nERROR: \n${error}`);
          });

          const userDocSub = this.angularFirestore.doc(`users/${userId}`).valueChanges().subscribe(data => {
            const d = data as any;
            this.checkForForcedReload(d);
            if (d) {
              this.userObjSubject.next({
                id: this.userId, userName: d?.hasOwnProperty('userName') ? d.userName : userId, pp: d.pp,
              });
            }
          }, e => {
            console.error(`user document fetch failed ${userId}`);
            throw e;
          });
          this.fireAuthService.setLogoutCallback('user-document', () => {
            userDocSub.unsubscribe();
          });

          // TODO: Should really update last seen according to this? Can I? DO IT NOW!
          const visited: string[] = [];

          const pageAlert = (url: string) => {
            url = url.includes('/') ? url.substr(0, url.indexOf('/')) : url;
            visited.push(url);
            this.angularFirestore.doc(`users/${userId}/page_alerts/${url}`).ref.get().then(d => {
              if (d.exists) {
                const data = d.data() as any;
                this.userObj.pipe(take(1)).toPromise().then((uo: IUser) => {
                  const userName = uo.userName;

                  if (!data.cssClass) {
                    data.cssClass = [];
                  }

                  if (typeof data.cssClass === 'string') {
                    data.cssClass = [data.cssClass];
                  }

                  if (!data.cssClass.includes('page-alert-modal')) {
                    data.cssClass.push('page-alert-modal');
                  }
                  data.cssClass.push('custom-alert');

                  if (!data.backdropDismiss) {
                    data.backdropDismiss = true;
                  }
                });
              }
            });
          };
          router.events.subscribe(obs => {
            if (obs.hasOwnProperty('urlAfterRedirects') && !obs.hasOwnProperty('state')) {
              const url = ((obs as any).urlAfterRedirects as string).replace('/', '--');

              if (!visited.includes(url)) {
                pageAlert(url);
              }
            }
          });

          setTimeout(() => {
            const url = this.router.url.replace('/', '--');
            pageAlert(url);
          }, 1500);
        }
      } else if (userAccessSub) {
        this.router.navigate(['login']).then();
        userAccessSub.unsubscribe();
      }
    }, error => {
      console.error('user timing error\n' + error);
    });
  }

  get userObj(): Observable<IUser> {
    return this.userObjSubject.asObservable();
  }

  get stores(): Observable<{ stores: { [id: string]: StoreInfo }; order: string[] }> {
    return this.storesMapObs.asObservable();
  }

  get colleagues(): Observable<{
    users: { [userId: string]: Colleague }; storesUsers: { [storeId: string]: string[] };
  }> {
    return this.colleaguesBS.asObservable();
  }


  //TODO check here and look at the service for images in community form update branch
  getImageUrl(path: string): Observable<string> {
    const ref = this.angularFireStorage.ref(path);
    return ref.getDownloadURL();
  }

  * sliceArrayForFBQueries<T>(values: T[]): Generator<T[]> {
    const MAX_FB_ARRAY_QUERY_SIZE = 30;
    let idx = 0;

    while (idx < values.length) {
      yield values.slice(idx, idx + MAX_FB_ARRAY_QUERY_SIZE);
      idx += MAX_FB_ARRAY_QUERY_SIZE;
    }
  }

  async subColleaguesAccess(stores: string | string[]): Promise<Observable<{ [userId: string]: IUserAccess }>> {
    const storeList = typeof stores === 'string' ? [stores] : stores;
    return this.angularFirestore.collection('/user_access/', (ref) =>
      ref.where('storeList', 'array-contains-any', storeList),
    ).valueChanges({idField: 'userId'}).pipe(mergeMap((data) => {
      const ua: { [userId: string]: IUserAccess } = {};
      data.forEach((access) => {
        // TODO: Very dangerous that the server user is found here
        // @ts-ignore
        if (access.userId === this.userId || access.isServer) {
          return;
        }
        ua[access.userId] = access as any as IUserAccess;
        delete access.userId;
      });
      return [ua];
    }));
  }

  pageStores(page: string): Observable<StoreObject> {
    const rules = pageRules(page);
    return this.stores.pipe(mergeMap((stores) => {
      const filtered: { order: string[]; stores: { [storeId: string]: StoreInfo } } = {order: [], stores: {}};
      filtered.order = stores.order.filter((storeId) => {
        if (rules !== false) {

          for (const rule of rules as RuleHumanID[]) {
            if (this.fireAuthService.hasAccess(storeId, {ruleID: rule}) !== true) {
              return false;
            }
          }
          filtered.stores[storeId] = stores.stores[storeId];
          return true;
        }
        return false;
      });
      return [filtered];
    }));
  }

  updateLastSeen(): void {
    if (!this.userId) {
      return;
    }
    const pathList = this.router.url.split('?')[0].split('/').filter((part: string) => part !== '');
    const feature = 'shared';
    if (pathList[0] === 'home') {
      return;
    }
    const batch = this.angularFirestore.firestore.batch();
    const update = {time: SERVER_TIMESTAMP(), path: pathList};
    const fieldPath = new FieldPath(this.userId);

    for (const store of Object.keys(this.userAccess.stores)) {
      // Make sure to add a unique document ID at the end of the path
      const doc = `${feature}/stores_data/${store}/messages/from_app/last_seen/`;
      const docRef = this.angularFirestore.doc(doc).ref;

      // Set using the FieldPath in a dynamic way to update specific fields
      batch.set(docRef, {[this.userId]: update}, {merge: true});
    }

    try {
      batch.commit().then().catch(e => {
        console.error('Update Last Seen Error:\n' + e);
      });
    } catch (e) {
      console.error('Caught an error before commit:\n' + e);
    }
  }

  /* ---------------------------------------------------------------------------------------------------------------- *\
  //                                                    USER PATHS                                                    //
  /* ---------------------------------------------------------------------------------------------------------------- */

  getUSERDepSalesSumSettings(): Promise<{ growthTargets: { [key: string]: number } }> {
    return new Promise<{ growthTargets: { [key: string]: number } }>((resolve, reject) => {
      this.getUserDocument('dep-sales-summary-settings').then(
        doc => {
          resolve(doc.data() as { growthTargets: { [key: string]: number } });
        },
      ).catch(e => {
        e.message = `Get user dep sales summary error.\n${e.message}`;
        reject(e);
      });
    });
  }

  getUserDocument(document?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.userId) {
        if (document) {
          this.angularFirestore.doc(`users/${this.userId}/singular_documents/${document}`)
            .get().toPromise().then(d => {
            resolve(d.data());
          }).catch(e => {
            e.message = `Error getting user document: ${document}.\n${e.message}`;
            reject(e);
          });
        } else {
          this.angularFirestore.doc(`users/${this.userId}`).get().toPromise().then(d => {
            resolve(d.data());
          }).catch(e => {
            e.message = `Error getting user document.\n${e.message}`;
            reject(e);
          });
        }
      } else {
        reject(Error('userId not initialised'));
      }
    });
  }

  updateUSERDepSalesSumSettings(updates: { [key: string]: any }): Promise<void> {
    return this.updateUserSingularDocuments('dep-sales-summary-settings', updates);
  }

  async getUserPreferences<T>(document: string, defaultFactory?: () => T): Promise<T> {
    try {
      const doc = await this.angularFirestore.doc(`users/${this.userId}/saved_preferences/${document}`).get().toPromise();
      if (doc.exists) {
        return doc.data() as T | any;
      } else if (defaultFactory) {
        return defaultFactory();
      }
      return undefined;
    } catch (e) {
      e.message = `Error fetching user Preference Document: ${document}\n${e.message}`;
      throw e;
    }
  }

  async updateUserPreferences(document: string, updates: any): Promise<void> {
    try {
      await this.angularFirestore.doc(`users/${this.userId}/saved_preferences/${document}`).set(updates, {merge: true});
    } catch (e) {
      e.message = `Error updating user Preference Document: ${document}\n${e.message}`;
      throw e;
    }
  }

  updateUserSingularDocuments(document: string, updates: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.angularFirestore.doc(`users/${this.userId}/singular_documents/${document}`).update(updates)
        .then(() => {
          resolve();
        }).catch(_ => {
        //  TODO: Check the document isn't accidentally overwritten
        this.angularFirestore.doc(`users/${this.userId}/singular_documents/${document}`).set(updates)
          .then(() => {
            resolve();
          })
          .catch(e => {
            e.message = `Error updating user document: ${document}\n${e.message}`;
            reject(e);
          });
      });
    });
  }

  setUserSingularDocuments(document: string, updates: any, merge: boolean = false): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.angularFirestore.doc(`users/${this.userId}/singular_documents/${document}`)
        .set(updates, {merge}).then(() => {
        resolve();
      }).catch(e => {
        e.message = `Error setting user singular document: ${document}\n${e.message}`;
        reject(e);
      });
    });
  }

  setUserDocument(userId: string, userDocument: IUser, merge: boolean = true): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.angularFirestore.doc(`users/${userId}`)
        .set(userDocument, {merge}).then(() => {
        resolve();
      }).catch(e => {
        e.message = `Error setting user document: \n${e.message}`;
        reject(e);
      });
    });
  }


  subMessagesQuery(types: MsgType[], options: {
    from?: Date; to?: Date; success?: boolean; sender?: string; pathValues?: [string, string][];
  } = {}, desc: boolean = true) {
    return new Observable<{ order: string[]; messages: { [id: string]: Message } }>(observer => {
      this.angularFirestore.collection(`users/${this.userId}/messages`, ref => {
        let qr: firebase.default.firestore.Query = ref;

        if (types.length === 1) {
          qr = qr.where('type', '==', types[0]);
        } else if (types.length > 1) {
          types = [...new Set(types)];

          if (types.length <= 10) {
            qr = qr.where('type', 'in', types);
          } else {
            observer.error(Error('List of types must be at most 10 strings.'));
          }
        }

        if (options.from) {
          qr = qr.where('timestamp', '>=', options.from);
        }
        if (options.to) {
          qr = qr.where('timestamp', '<=', options.to);
        }
        if (options.success !== undefined) {
          qr = qr.where('payload.success', '==', options.success);
        }
        if (options.sender) {
          qr = qr.where('sender', '==', options.sender);
        }

        if (options.pathValues && options.pathValues.length > 0) {
          for (const pv of options.pathValues) {
            qr = qr.where(pv[0], '==', pv[1]);
          }
        }
        qr.orderBy('timestamp', desc ? 'desc' : 'asc');
        return qr;
      }).snapshotChanges().subscribe(docChanges => {
        const messages: { [id: string]: Message } = {};
        const order = docChanges.map(dc => {
          messages[dc.payload.doc.id] = dc.payload.doc.data() as Message;
          messages[dc.payload.doc.id].timestamp = (messages[dc.payload.doc.id].timestamp as any).toDate();
          return dc.payload.doc.id;
        });
        observer.next({order, messages});
      }, error => {
        error.message = `Error in message subscription query\n${error.message}`;
        observer.error(error);
      });
    });
  }

  deleteMessage(id: string): Promise<void> {
    return this.angularFirestore.doc(`/users/${this.userId}/messages/${id}`).delete();
  }

  /* ---------------------------------------------------------------------------------------------------------------- *\
  //                                               Adding Users                                                       //
  \* ---------------------------------------------------------------------------------------------------------------- */

  async getGroupAccessSettings(): Promise<IGroupAccess> {
    const result: IGroupAccess = {};

    try {
      const snapshot = await this.angularFirestore.collection('/global/settings/group_access').ref.get();
      snapshot.forEach(doc => {
        const data = doc.data() as IDefaultGroupAccessDocument;
        if (data.groupName) { // Ensure groupName exists in the document
          result[data.groupName] = {
            accessCode: data.accessCode,
            orderPriority: data.orderPriority,
          };
        }
      });
    } catch (error) {
      console.error("Error fetching group access settings:", error);
    }

    return result;
  }


  /* ---------------------------------------------------------------------------------------------------------------- *\
  //                                               Single Docs PATHS                                                  //
  \* ---------------------------------------------------------------------------------------------------------------- */

  async getPriceBandingForStores(): Promise<IPriceBandPerStore> {
    const storeIds: string[] = this.storesMapObs.value.order
      .filter((storeId: string) => this.fireAuthService.hasAccess(storeId, {ruleID: 'b.i'}));
    const results: IPriceBandPerStore = {};
    const documentPromises: Promise<void>[] = [];
    storeIds.forEach((storeId: string): void => {
      documentPromises.push(
        this.collectionStoresSettingsService.getDocument<IPriceBand>(path_stores_storeId_settings_price_bands(storeId))
          .then((data: { [index: number]: IPriceBand }): void => {
            if (!data) {
              results[storeId] = null;
            } else {
              results[storeId] = [];
              Object.keys(data).forEach((index: string): void => {
                results[storeId].push(data[index] as IPriceBand);
              });
            }
          }),
      );
    });
    await Promise.all(documentPromises);
    return results;
  }

  // Old get price banding - deprecated
  async getPriceBands(): Promise<{ personal?: PriceBand[]; stores: { [storeId: string]: PriceBand[] } }> {
    const storeIds = this.storesMapObs.value.order;
    storeIds.filter((sID) => this.fireAuthService.hasAccess(sID, {ruleID: 'b.i'}));
    const result: { personal: PriceBand[]; stores: { [storeId: string]: PriceBand[] } } = {personal: null, stores: {}};
    const promises: Promise<void>[] = [];

    // promises.push(new Promise<void>((resolve, reject) =>
    //   this.getUserDocument('price_bands').then((data) => {
    //     if (data) {
    //       result.personal = [];
    //       for (let i = 0; i < Object.keys(data).length; i++) {
    //         result.personal.push(data[i]);
    //       }
    //     }
    //     resolve();
    //   })
    // ));
    storeIds.forEach((sID) => promises.push(new Promise<void>((resolve, reject) =>
      this.collectionStoresSettingsService.getDocument<IPriceBand>(path_stores_storeId_settings_price_bands(sID)).then((data) => {
        if (data) {
          result.stores[sID] = [];
          for (let i = 0; i < Object.keys(data).length; i++) {
            result.stores[sID].push(data[i]);
          }
        } else {
          result.stores[sID] = null;
        }
        resolve();
      }),
    )));
    await forkJoin(promises).toPromise();
    return result;
  }

  getStoreDataDoc(document: string, storeId: string, feature: Feature = 'shared'): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.angularFirestore.doc(`${feature}/stores_data/${storeId}/data/singular_documents/${document}`)
        .get().toPromise().then(doc => {
        resolve(doc.data());
      }).catch(e => {
        e.message = `Error getting store data doc: ${feature} -> ${document}\n${e.message}`;
        reject(e);
      });
    });
  }

  getStoreDataDocTyped<DocType>(document: string, store: IStore, feature: Feature = 'shared'): Promise<DocType> {
    return new Promise<DocType>((resolve, reject): void => {
      this.angularFirestore.doc(`${feature}/stores_data/${store.storeId}/data/singular_documents/${document}`)
        .get()
        .toPromise()
        .then((doc: DocumentSnapshot<DocType>) => {
          resolve(doc.data());
        }).catch((error): void => {
        error.message = `Error getting store data doc: ${feature} -> ${document}\n${error.message}`;
        reject(error);
      });
    });
  }

  getDepartmentsByStore(store: IStore): Observable<IDepartment[]> {
    const departments$ = this.getStoreDataDocTyped<{ [depCode: string]: IDepartment }>('departments', store);
    const subDepartments$ = this.getStoreDataDocTyped<{ [depCode: string]: ISubDepartment }>('sub_departments', store);
    return forkJoin([departments$, subDepartments$]).pipe(
      map(([departments, subDepartments]) => {
        const deps: IDepartment[] = [];
        const subDepsAll: ISubDepartment[] = [];
        Object.keys(subDepartments).forEach((key: string): void => {
          subDepsAll.push({
            subDep: key,
            dep: subDepartments[key].dep,
            name: subDepartments[key].name,
            targetGP: subDepartments[key].targetGP,
          } as ISubDepartment);
        });
        Object.keys(departments).forEach((key: string) => {
          deps.push({
            dep: key,
            name: departments[key].name,
            subDeps: subDepsAll.filter((dep: ISubDepartment): boolean => dep.dep === key),
          } as IDepartment);
        });
        return deps;
      }),
    );
  }

  async getStoreEditFlagConfig(storeId: string): Promise<StockValChangeCheckConfig> {
    const config = await this.collectionStoresSettingsService.getDocument<StockValChangeCheckConfig>(path_stores_storeId_settings_stock_edit_thresholds(storeId));

    if (config) {
      this.sVChangeCheckConfigs[storeId] = config;
    } else {
      this.sVChangeCheckConfigs[storeId] = {
        sellPriIncl1: {
          pct: 0.2, negPct: 0.1, noNeg: false, noZero: false,
        },
      };
    }
    return this.sVChangeCheckConfigs[storeId];
  }

  countStoreCollection(col: string, storeId: string, feature: Feature = 'shared') {
    // this.af.firestore.collection(`${feature}/stores_data/${storeId}/data/${col}`).count
  }

  updateStoreDataDoc(document: string, storeId: string, data: any, feature: Feature = 'shared',
                     set: boolean = false, safeFieldPath: boolean = false): Promise<void> {
    if (document === 'price_bands') {
      return new Promise<void>((resolve, reject) => {
        this.collectionStoresSettingsService.setDocument<IPriceBand>(path_stores_storeId_settings_price_bands(storeId), data as IPriceBand)
          .then(() => {
            resolve();
          })
          .catch(e => {
            e.message = `Error getting price bands for store: ${storeId}\n${e.message}`;
            reject(e);
          });
      });
    } else if (document === 'stock_edit_thresholds') {
      return new Promise<void>((resolve, reject) => {
        this.collectionStoresSettingsService.setDocument<StockValChangeCheckConfig>(path_stores_storeId_settings_stock_edit_thresholds(storeId), data as StockValChangeCheckConfig)
          .then(() => {
            resolve();
          })
          .catch(e => {
            e.message = `Error getting price bands for store: ${storeId}\n${e.message}`;
            reject(e);
          });
      });
    } else {
      return new Promise<void>((resolve, reject) => {
        const path = `${feature}/stores_data/${storeId}/data/singular_documents/${document}`;
        const doc = !safeFieldPath ? this.angularFirestore.doc(path) : this.angularFirestore.firestore.doc(path);
        const update: any[] = [];

        if (safeFieldPath) {
          Object.keys(data).forEach(key => update.push(new FieldPath(key), data[key]));
        }

        if (set) {
          if (!safeFieldPath) {
            doc.set(data).then(() => {
              resolve();
            }).catch(e => {
              e.message = `Error setting store data doc: ${feature} -> ${document}\n${e.message}`;
              reject(e);
            });
          } else {
            doc.set.apply(doc, update).then(() => {
              resolve();
            }).catch(e => {
              e.message = `Error setting store data doc (with FieldPath): ${feature} -> ${document}\n${e.message}`;
              reject(e);
            });
          }
        } else {
          let p = !safeFieldPath ? doc.update(data) : doc.update.apply(doc, update);
          p.then(() => {
            resolve();
          }).catch(e => {
            if (e.name === 'FirebaseError' && e.code === 'not-found') {
              p = !safeFieldPath ? doc.set(data) : doc.set.apply(doc, update);
              p.then(() => {
                resolve();
              }).catch(_ => {
                e.message = `Error updating store data doc after doc was not found ` +
                  `${safeFieldPath ? ' (with FieldPath)' : ''}: ${feature} -> ${document}\n${e.message}`;
                reject(e);
              });
            } else {
              e.message = `Error updating store data doc${safeFieldPath ? ' (with FieldPath)' : ''}: ` +
                `${feature} -> ${document}\n${e.message}`;
              reject(e);
            }
          });
        }
      });
    }

  }

  subStoreDataDoc(document: string, storeId: string, feature: Feature = 'shared'): Observable<any> {
    if (!this.storeDataSubs[storeId]) {
      this.storeDataSubs[storeId] = {};
    }

    if (!this.storeDataSubs[storeId][document]) {
      this.storeDataSubs[storeId][document] = new Observable<any>(observer => {
        this.angularFirestore.doc(`${feature}/stores_data/${storeId}/data/singular_documents/${document}`)
          .valueChanges().subscribe(doc => {
          observer.next(doc);
        }, error => {
          error.message = `Error on store data doc subscription: ${feature} -> ${document}\n${error.message}`;
          observer.error(error);
        });
      });
    }
    return this.storeDataSubs[storeId][document];
  }

  async getStoreDoc(docPath: string, storeId: string, feature: Feature = 'shared'): Promise<object> {
    try {
      const doc = await this.angularFirestore.doc(`${feature}/stores_data/${storeId}/${docPath}`).get().toPromise();
      return doc.data() as object;
    } catch (error) {
      error.message = `Error in getStoreDoc. Error getting store data doc: ${feature} -> ${document}`;
      throw error;
    }
  }

  subStoreDoc(docPath: string, storeId: string, feature: Feature = 'shared'): Observable<unknown> {
    return this.angularFirestore.doc(`${feature}/stores_data/${storeId}/${docPath}`).valueChanges().pipe(mergeMap((data) => [data]));

    // { observer: Observable<any>; unsub: () => void } {
    // // TODO: Gross, must redo with map
    // let sub: Subscription;
    // const observer = new Observable<any>(obs => {
    //   sub = this.af.doc(`${feature}/stores_data/${storeId}/${docPath}`)
    //     .snapshotChanges().subscribe(
    //       doc => {
    //         obs.next(doc.payload.data());
    //       },
    //       error => {
    //         error.message = `subStoreDoc failed: ${feature} -> ${docPath}\n${error.message}`;
    //         obs.error(error);
    //       });
    // });
    // const unsub = () => {
    //   if (sub) {
    //     sub.unsubscribe();
    //     sub = null;
    //   }
    // };
    // return {observer, unsub};
  }

  /* ---------------------------------------------------------------------------------------------------------------- *\
  //                                             TEMP SALES STUFF                                                     //
  \* ---------------------------------------------------------------------------------------------------------------- */

  /**
   * Query firebase for sales history of provided store. History can be filtered to fall between two dates and/or
   * belong to 1 to 10 departments. The object returned by the promise (DepSalesHistory) will have redundant copies of
   * the data, byYear and byDep, as nested Map objects. These objects are the exact same data but byYear is prioritizes
   * groupings by date and byDep priorities groupings by department key.
   * E.g. to select the history for department x on date DD-MM-YYYY one can index the data in one of the 2 following
   * ways.
   *    data.byYear.get(YYYY).get(MM).get(DD)[x] or
   *    data.byDep[x].get(YYYY).get(MM).get(DD)
   *
   * If param dayTotals is true there will be a third Map in the data containing the totals of all departments
   *
   * @param storeId - Firebase ID of the store to be queried.
   * @param startDate - Provide a start date for history. History would only go back a max of 5 years.
   * @param endDate - Provide an end date for history.
   * @param departments - 1 to 10 department filters. Department key strings.
   * @param dayTotals - Add a map that provides the totals of all departments for each day.
   * @param fullMonth Ignore the days of the startDate or endDate parameters and instead provide full months. useful
   *  to get a full months' history without having to worry about how many days are in the month. For example the
   *  full history for June 2022 can be retrieved by providing startDate and endDate both being any date in June 2022.
   * @returns Promise<DepSalesHistory>
   */
  salesHist(storeId: string, startDate?: Date, endDate?: Date, departments?: string[] | string,
            dayTotals: boolean = true, fullMonth: boolean = false):
    Promise<DepSalesHistory> {

    if (departments && typeof departments !== 'string' && departments.length > 10) {
      throw new Error('Sales History Query Error. 0 to 10 department keys can be queried. ' +
        'No more then 10 departments allowed.');
    }
    let qStartDate: Date;
    let qEndDate: Date;

    if (startDate) {
      qStartDate = new Date(`${startDate.getFullYear()}-${startDate.getMonth() + 1}-01`);
      qStartDate.setHours(0, 0, 0, 0);
    }

    if (endDate) {
      qEndDate = new Date(`${endDate.getFullYear()}-${endDate.getMonth() + 1}-01`);
      qEndDate.setDate(34);
      qEndDate.setDate(2);
      qEndDate.setHours(0, 0, 0, -1);
    }

    return new Promise<DepSalesHistory>((resolve, reject) => {
      this.angularFirestore.collection(`observation/stores_data/${storeId}/data/sales_hist`, ref => {
        let reference: any = ref;
        reference = qStartDate ? reference.where('ts', '>=', qStartDate) : reference;
        reference = qEndDate ? reference.where('ts', '<=', qEndDate) : reference;
        reference = departments ?
          reference.where('dep', (typeof departments === 'string' ? '==' : 'in'), departments) : reference;
        return reference;
      }).get().toPromise().then(results => {
        const byYear = new Map<number, Map<number, Map<number, { [dep: string]: DepSales2 }>>>();
        const byDep: { [dep: string]: Map<number, Map<number, Map<number, DepSales2>>> } = {};
        const totals: Map<number, Map<number, Map<number, DepSales2>>> = dayTotals ? new Map() : null;
        results.docs.map(ds => {
          const data = ds.data() as { days: { [day: number]: number[] }; dep: string; ts: Date };
          data.ts = (data.ts as any).toDate();
          const year = data.ts.getFullYear();
          const month = data.ts.getMonth() + 1;

          if (byYear.has(year)) {
            if (!byYear.get(year).has(month)) {
              byYear.get(year).set(month, new Map<number, { [dep: string]: DepSales2 }>());

              if (totals) {
                totals.get(year).set(month, new Map<number, DepSales2>());
              }
            }
          } else {
            byYear.set(year, new Map<number, Map<number, { [dep: string]: DepSales2 }>>());
            byYear.get(year).set(month, new Map<number, { [dep: string]: DepSales2 }>());

            if (totals) {
              totals.set(year, new Map<number, Map<number, DepSales2>>());
              totals.get(year).set(month, new Map<number, DepSales2>());
            }
          }

          for (const day of Object.keys(data.days)) {
            let addDay = fullMonth;

            if (!addDay) {
              const date = new Date(`${year}-${month}-${day}`);
              addDay = (!startDate || date >= startDate) && (!endDate || date <= endDate);
            }

            if (addDay) {
              if (!byYear.get(year).get(month).has(+day)) {
                byYear.get(year).get(month).set(+day, {});

                if (totals) {
                  totals.get(year).get(month).set(+day, {netCost: 0, netSales: 0, customerCount: 0});
                }
              }
              const depSales: DepSales2 = {
                netSales: data.days[day][0], netCost: data.days[day][1],
                customerCount: data.days[day][2],
              };
              byYear.get(year).get(month).get(+day)[data.dep] = depSales;

              if (totals) {
                const t = totals.get(year).get(month).get(+day);
                t.netSales += depSales.netSales;
                t.netCost += depSales.netCost;
                t.customerCount += depSales.customerCount;
              }

              if (byDep.hasOwnProperty(data.dep)) {
                if (byDep[data.dep].has(year)) {
                  if (!byDep[data.dep].get(year).has(month)) {
                    byDep[data.dep].get(year).set(month, new Map<number, DepSales2>());
                  }
                } else {
                  byDep[data.dep].set(year, new Map<number, Map<number, DepSales2>>());
                  byDep[data.dep].get(year).set(month, new Map<number, DepSales2>());
                }
              } else {
                byDep[data.dep] = new Map<number, Map<number, Map<number, DepSales2>>>();
                byDep[data.dep].set(year, new Map<number, Map<number, DepSales2>>());
                byDep[data.dep].get(year).set(month, new Map<number, DepSales2>());
              }
              byDep[data.dep].get(year).get(month).set(+day, depSales);
            }
          }
        });
        const result: DepSalesHistory = {byYear, byDep};

        if (totals) {
          result.totals = totals;
        }
        resolve(result);
      }).catch(error => {
        error.message = `Failed to get Sales History\n${error.message}`;
        reject(error);
      });
    });
  }


  /* ---------------------------------------------------------------------------------------------------------------- *\
  //                                               SHARED PATHS                                                       //
  \* ---------------------------------------------------------------------------------------------------------------- */

  queryStock(storeId: string, queries: FbQuery[], asArray: boolean = false):
    Promise<{ ids: string[]; items: { [id: string]: any } } | any[]> {
    return new Promise<{ ids: string[]; items: { [id: string]: any } } | any[]>((resolve, reject) => {
      this.stockQueryRef(storeId, queries).get().pipe(take(1)).toPromise().then(result => {
        if (!asArray) {
          const items = {};
          const ids = result.docs.map(d => {
            items[d.id] = d.data();
            return d.id;
          });
          resolve({ids, items});
        } else {
          const items = result.docs.map(d => {
            const item = d.data();
            item[sItemKeyToInt.code] = d.id;
            return item;
          });
          resolve(items);
        }
      }).catch(e => {
        e.message = `Error on stock query\n${e.message}`;
        reject(e);
      });
    });
  }

  queryStock2(storeId: string, queries: FbQuery[], cast?: boolean, objectify?: boolean): Promise<
    StockItem[] | any[] | { ids: string[]; items: { [objectID: string]: any } | { [objectID: string]: StockItem } }
  > {
    // TODO (AO Data restructure branch has many helpfull types and functions for this

    return new Promise<
      StockItem[] | any[] | { ids: string[]; items: { [objectID: string]: any } | { [objectID: string]: StockItem } }
    >((resolve, reject) => {
      // TODO: ---------------------------------------------------------------------------------------------------------
      //                                                    finish
      //  --------------------------------------------------------------------------------------------------------------
      this.stockQueryRef(storeId, queries).get().toPromise().then((result) => {
        if (objectify) {
          const pack = {items: {}} as
            { ids: string[]; items: { [objectID: string]: any } | { [objectID: string]: StockItem } };
          pack.ids = result.docs.map((doc) => {
            pack.items[doc.id] = cast ? castStockItem(doc.data()) : doc.data();
            return doc.id;
          });
          resolve(pack);
        } else {
          resolve(result.docs.map((doc) => {
            const data = doc.data();
            data.objectID = doc.id;
            const item: StockItem | any = cast ? castStockItem(data) : data;
            return item;
          }));
        }
      });
    });
  }

  subQueryStock(storeId: string, queries: FbQuery[], cast?: boolean | (keyof StockItem)[], objectify?: boolean,
  ): Observable<StockItem[] | any[] | {
    ids: string[];
    items: { [objectID: string]: any } | { [objectID: string]: StockItem }
  }> {
    return this.stockQueryRef(storeId, queries).valueChanges({idField: 'objectID'}).pipe(mergeMap((next) => {
      if (objectify) {
        const pack = {items: {}} as
          { ids: string[]; items: { [objectID: string]: any } | { [objectID: string]: StockItem } };

        if (cast) {
          pack.ids = next.map((rawItem) => {
            pack.items[rawItem.objectID] = castStockItem(rawItem as RawStockItem, cast === true ? 'ALL' : cast);
            return rawItem.objectID;
          });
        } else {
          pack.ids = next.map((rawItem) => {
            pack.items[rawItem.objectID] = rawItem;
            return rawItem.objectID;
          });
        }
        return [pack];
      }

      if (cast) {
        return [next.map((rawItem) => castStockItem(rawItem as RawStockItem, cast === true ? 'ALL' : cast))];
      }
      return [next] as any[];
    }));
  }

  getStockItems(
    store: IStore,
    firebaseQueries: IFirebaseQuery[],
    idField: string,
  ): Observable<StockItem[]> {
    return this.stockQueryRef(store.storeId, firebaseQueries)
      .valueChanges({idField})
      .pipe(
        map((documents: DocumentData[]) => {
          const stockItems = documents.map((doc: DocumentData) =>
            castObjectToType<StockItem, DocumentData>(doc),
          );
          return stockItems;
        }),
      );
  }

  stockQueryRef(storeId: string, queries: FbQuery[]): AngularFirestoreCollection {
    return this.angularFirestore.collection(`shared/stores_data/${storeId}/data/stock`, ref => {
      let currentRef = ref;

      for (const q of queries) {
        switch (q.q) {
          case 'where':
            currentRef = currentRef.where.apply(currentRef, q.p);
            break;
          case 'orderBy':
            currentRef = currentRef.orderBy.apply(currentRef, q.p);
            break;
          case 'limit':
            currentRef = currentRef.limit.apply(currentRef, q.p);
            break;
          case 'startAt':
            currentRef = currentRef.startAt.apply(currentRef, q.p);
            break;
          case 'startAfter':
            currentRef = currentRef.startAfter.apply(currentRef, q.p);
            break;
          case 'endAt':
            currentRef = currentRef.endAt.apply(currentRef, q.p);
            break;
          case 'endBefore':
            currentRef = currentRef.endBefore.apply(currentRef, q.p);
            break;
        }
      }
      return currentRef;
    });
  }

  getStockItem(storeId: string, code: string) {

    return new Promise<any>(resolve =>
      this.angularFirestore.doc(`shared/stores_data/${storeId}/data/stock/${code}`)
        .get().toPromise().then(data => {
        resolve(data.data());
      }),
    );
  }

  updateStockTags(storeId: string, stockIds: string | string[], tag: string, remove: boolean = false) {
    return new Promise<void>((resolve, reject) => {
      const update = {_tags: remove ? ARRAY_REMOVE(tag) : ARRAY_UNION(tag)};


      if (typeof stockIds === 'string') {
        this.angularFirestore.doc(`shared/stores_data/${storeId}/data/stock/${stockIds}`)
          .update(update).then(resolve)
          .catch(e => {
            e.message = `Failed to union tags ${tag} to code ${stockIds} on store ${storeId}.\n${e.message}`;
            reject(e);
          });
      } else {
        const promises: Promise<void>[] = [];

        while (stockIds.length > 0) {
          const batch = this.angularFirestore.firestore.batch();

          for (const code of stockIds.splice(Math.max(0, stockIds.length - 500), 500)) {
            batch.update(this.angularFirestore.doc(`shared/stores_data/${storeId}/data/stock/${code}`).ref, update);
          }
          promises.push(batch.commit());
        }

        if (promises.length === 1) {
          promises[0].then(() => {
            resolve();
          })
            .catch(e => {
              e.message = `Failed to union tags ${tag} to codes ${stockIds} on store ${storeId}.\n${e.message}`;
              reject(e);
            });
        } else {
          forkJoin(promises).toPromise().then(() => {
            resolve();
          })
            .catch(e => {
              e.message = `Failed to union tags ${tag} to codes ${stockIds} on store ${storeId}.\n${e.message}`;
              reject(e);
            });
        }
      }
    });
  }

  // getAllStockItems(storeId: string) {
  //   return new Promise<{ [code: string]: any }>(resolve =>
  //     this.af.collection(`shared/stores_data/${storeId}/data/stock`)
  //       .snapshotChanges().pipe(take(1)).toPromise().then(stock => {
  //         const stockItems: { [code: string]: any } = {};
  //         stock.map(d => stockItems[d.payload.doc.id] = d.payload.doc.data());
  //         resolve(stockItems);
  //     })
  //   );
  // }
  //
  // async fixStockStringDates(storeId: string) {
  //   console.clear();
  //   const all = await this.getAllStockItems(storeId);
  //   const dateKeys = ['6', '7', '8', '10', '28', '29', '30', '31', '32', '41', '47'];
  //   const updates = {};
  //
  //   for (const code of Object.keys(all)) {
  //     for (const key of dateKeys) {
  //       if (all[code][key] && all[code][key] !== '' && typeof all[code][key] === 'string') {
  //         const date = strToDate(all[code][key]);
  //
  //         if (date) {
  //           if (!updates.hasOwnProperty(code)) { updates[code] = {}; }
  //           updates[code][key] = date;
  //         }
  //       }
  //     }
  //   }
  //   const codes = Object.keys(updates);
  //
  //
  //
  //   while (codes.length > 0) {
  //     const batch = this.af.firestore.batch();
  //     const splice = codes.splice(0, Math.min(codes.length, 499));
  //
  //     for (const code of splice) {
  //       const dr = this.af.doc(`shared/stores_data/${storeId}/data/stock/${code}`).ref;
  //       batch.update(dr, updates[code]);
  //     }
  //     await batch.commit();
  //   }
  //
  // }

  subSuppliersOnceOff(storeId: string): { 'obs': Observable<{ [account: string]: Supplier }>; 'sub': Subscription } {
    let sub: Subscription;

    // Wrap in a try-catch for debugging purposes
    try {
      const obs = new Observable<{ [account: string]: Supplier }>(observer => {
        sub = this.angularFirestore.collection(`shared/stores_data/${storeId}/data/suppliers`).snapshotChanges()
          .subscribe(snaps => {
            const suppliers: { [account: string]: Supplier } = {};

            // Ensure we process only valid snapshot documents
            for (const snap of snaps) {
              if (snap.payload.doc.exists) {
                suppliers[snap.payload.doc.id] = snap.payload.doc.data() as Supplier;
              }
            }

            // Send data to the observer
            observer.next(suppliers);

          }, error => {
            console.error("Error fetching suppliers:", error);
            observer.error(error);
          });
      });

      return {obs, sub};
    } catch (error) {
      console.error("An error occurred while fetching suppliers:", error);
      return {obs: new Observable(), sub: new Subscription()}; // Return fallback if something fails
    }
  }

  getSuppliers(storeId: string): Promise<{ [account: string]: Supplier }> {
    return new Promise<{ [account: string]: Supplier }>((resolve, reject) =>
      this.angularFirestore.collection(`shared/stores_data/${storeId}/data/suppliers`).ref.get().then(d => {
        const suppliers = {};
        d.docs.forEach(value => suppliers[value.id] = value.data());
        resolve(suppliers);
      }).catch(error => {
        error.message = `Failed to get suppliers ${storeId}.\n${error.message}`;
        reject(error);
      }),
    );
  }


  /* ---------------------------------------------------------------------------------------------------------------- *\
  //                                                      DANGER                                                      //
  \* ---------------------------------------------------------------------------------------------------------------- */

  async setLinkedStockItems(links: { [linkHash: string]: Map<string, string> }) {
    let batch = this.angularFirestore.firestore.batch();
    let count = 0;

    for (const hash of Object.keys(links)) {

      if (Object.keys(links[hash]).length + count > 500) {
        await batch.commit();
        batch = this.angularFirestore.firestore.batch();
        count = 0;
      }

      for (const storeId of Object.keys(links[hash])) {
        batch.update(
          this.angularFirestore.doc(`shared/stores_data/${storeId}/data/stock/${links[hash][storeId]}`).ref,
          {link: hash, _tags: ARRAY_UNION('linked_private')},
        );
      }
      count++;
    }

    if (count > 0) {
      await batch.commit();
    }
  }

  async stockItemsDeleteLink(items: { [storeId: string]: string[] }) {
    const batch = this.angularFirestore.firestore.batch();

    for (const storeId of Object.keys(items)) {
      for (const code of items[storeId]) {
        batch.update(
          this.angularFirestore.doc(`shared/stores_data/${storeId}/data/stock/${code}`).ref,
          {
            link: DELETE_FILED(),
            _tags: ARRAY_REMOVE('linked_private'),
          },
        );
      }
    }
    return batch.commit();
  }


  /* ---------------------------------------------------------------------------------------------------------------- *\
  //                                             OBSERVATION PATHS                                                    //
  \* ---------------------------------------------------------------------------------------------------------------- */

  subDepSalesSummary(storeId: string):
    Observable<{ store: string; data: { [department: string]: DepSales }; time: Date }> {

    return null;
  }

  getDepSalesSumSettings(storeId: string): Promise<{ growthTargets: { [key: string]: number } }> {
    return new Promise<{ growthTargets: { [key: string]: number } }>((resolve, reject) => {
      this.angularFirestore.doc(`observation/stores_data/${storeId}/data/singular_documents/dep-sales-summary-settings`)
        .get().toPromise().then(doc => {
        resolve(doc.data() as { growthTargets: { [key: string]: number } });
      }).catch(e => {
        reject(e);
      });
    });
  }

  updateDepSalesSumSettings(storeId: string, updates: { [key: string]: any }): Promise<void> {
    return new Promise<void>((resolve) => {
      this.angularFirestore.doc(`observation/stores_data/${storeId}/data/singular_documents/dep-sales-summary-settings`)
        .update(updates).then(() => {
        resolve();
      });
    });
  }

  /* ---------------------------------------------------------------------------------------------------------------- *\
  //                                              OPERATIONAL PATHS                                                   //
  \* ---------------------------------------------------------------------------------------------------------------- */

  getApiLog(storeId: string, apiLogID: string): Promise<ApiLogObj> {
    return new Promise<ApiLogObj>((resolve, reject) => {
      this.angularFirestore.doc(`/operational/stores_data/${storeId}/data/api_events/${apiLogID}`).get().toPromise().then((doc) => {
        const obj = doc.data();
        ['creationDate', 'scheduledDate', 'executedDate'].forEach((dk) => {
          if (obj[dk]) {
            obj[dk] = obj[dk].toDate();
          }
        });

        resolve(obj as ApiLogObj);
      }).catch(e => {
        e.message = `Error getting API log.\n${e.message}`;
        reject(e);
      });
    });
  }

  subAutoOrderingSchedule(storeId: string): Observable<
    { schedule: { [day: string]: string[] }; accounts: { [acc: string]: any } }
  > {
    if (!this.storeDataSubs[storeId].oaSchedule) {
      this.storeDataSubs[storeId].oaSchedule = new Observable<any>(observer => {
        this.angularFirestore.collection(`/operational/stores_data/${storeId}/data/auto_ordering/schedule/accounts`)
          .snapshotChanges()
          .subscribe(docChanges => {
            const schedule: { [day: string]: string[] } = {};
            const accounts = {};

            for (const dc of docChanges) {
              const acc = dc.payload.doc.id;
              accounts[acc] = dc.payload.doc.data();

              for (const day of Object.keys(accounts[acc])) {
                schedule[day] = schedule[day] ? schedule[day].concat(acc) : [acc];
              }
            }

            for (const day of Object.keys(schedule)) {
              schedule[day].sort((a, b) => accounts[a][day].rank - accounts[b][day].rank);
            }
            observer.next({schedule, accounts});
          });
      });
    }
    return this.storeDataSubs[storeId].oaSchedule;
  }

  updateAutoOrderingSchedules(storeId, accounts: { [acc: string]: any }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const params: { doc: firebase.default.firestore.DocumentReference; data: AOScheduleElement }[] = [];

      for (const acc of Object.keys(accounts)) {
        for (const day of Object.keys(accounts[acc])) {
          const doc = this.angularFirestore.doc(`/operational/stores_data/${storeId}/data/auto_ordering/schedule/${day}/${acc}`)
            .ref;
          const data = accounts[acc][day];

          if (data) {
            data.userId = this.userId;
          }
          params.push({doc, data});
        }
      }

      const upload = () => {
        if (params.length) {
          const batch = this.angularFirestore.firestore.batch();

          for (let i = 0; i < Math.min(500, params.length); i++) {
            const p = params.pop();

            if (p.data) {
              batch.set(p.doc, p.data);
            } else {
              batch.delete(p.doc);
            }
          }
          batch.commit().then(() => {
            upload();
          }).catch(error => {
            error.message = `Failed to update schedule.\n${error.message}`;
            reject(error);
          });
        } else {
          resolve();
        }
      };
      upload();
    });
  }

  getAutoOrderingScheduleSubs(storeId: string): { [day: string]: Observable<{ [acc: string]: AOScheduleElement }> } {
    const subs: { [day: string]: Observable<{ [acc: string]: AOScheduleElement }> } = {};
    const dayArr = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
    const path = `/operational/stores_data/${storeId}/data/auto_ordering/schedule`;

    for (const day of dayArr) {
      subs[day] = new Observable<{ [p: string]: AOScheduleElement }>(observer => {
        this.angularFirestore.collection(`${path}/${day}`).snapshotChanges().subscribe(dcs => {
          const accounts: { [acc: string]: AOScheduleElement } = {};
          dcs.map(dc => accounts[dc.payload.doc.id] = dc.payload.doc.data() as AOScheduleElement);
          observer.next(accounts);
        });
      });
    }
    return subs;
  }

  getAutoOrderingScheduleDay(storeId: string, day: string): Promise<{ [sup: string]: AOScheduleElement }> {
    return new Promise<{ [sup: string]: AOScheduleElement }>((resolve, reject) => {
      this.angularFirestore.collection(`/operational/stores_data/${storeId}/data/auto_ordering/schedule/${day}`)
        .get().toPromise().then(docs => {
        const orders = {};
        docs.docs.map(doc => orders[doc.id] = doc.data());
        resolve(orders);
      }).catch(e => {
        e.message = `Error getting AO Schedule for day ${day}.\n${e.message}`;
        reject(e);
      });
    });
  }

  getAutoOrderingSettings(storeId: string): Promise<AOSettings> {
    return new Promise<AOSettings>((resolve, reject) => {
      this.angularFirestore.doc(`/operational/stores_data/${storeId}/data/auto_ordering/user_settings/settings/${this.userId}`)
        .get().toPromise().then(doc => {
        resolve(doc.data() as AOSettings);
      }).catch(e => {
        e.message = `Error getting Auto Ordering Settings.\n${e.message}`;
        reject(e);
      });
    });
  }

  getAutoOrderingEmailSettings(storeId: string, userId: string): Promise<AOSettings> {
    return new Promise<AOSettings>((resolve, reject) => {
      this.angularFirestore.doc(`/operational/stores_data/${storeId}/data/auto_ordering/user-settings/settings/${userId}`)
        .get().toPromise().then(doc => {
        resolve(doc.data() as AOSettings);
      }).catch(e => {
        e.message = `Error getting Auto Ordering Settings.\n${e.message}`;
        reject(e);
      });
    });
  }

  subAutoOrderingEmailSettings(storeId: string): Observable<{ [userId: string]: AOSettings }> {
    return this.angularFirestore.collection(`/operational/stores_data/${storeId}/data/auto_ordering/user-settings/settings`)
      .valueChanges({idField: 'objectID'}).pipe(mergeMap((next) => {
        const settings: { [userId: string]: AOSettings } = {};
        next.forEach((doc) => {
          settings[doc.objectID] = doc as any as AOSettings;
          delete doc.objectID;
        });
        return [settings];
      }));
  }

  updateAutoOrderingSettings(storeId: string, settings: AOSettings): Promise<void> {
    return this.angularFirestore.doc(
      `/operational/stores_data/${storeId}/data/auto_ordering/user-settings/settings/${this.userId}`,
    ).set(settings, {merge: true});
  }

  updateAutoOrderingEmailSettings(storeId: string, userId: string, settings: AOSettings): Promise<void> {
    return this.angularFirestore.doc(
      `/operational/stores_data/${storeId}/data/auto_ordering/user-settings/settings/${userId}`,
    ).set(settings);
  }

  subAutoOrders(storeId: string): Observable<AutoOrderCollection> {
    return new Observable<AutoOrderCollection>((subscriber: Subscriber<AutoOrderCollection>) => {

      const process = async (docs): Promise<void> => {
        const aoCollection: AutoOrderCollection = {};
        await Promise.all(
          docs.map(async (doc) => {
            const docData = doc.data();
            const autoOrder: AutoOrder = {
              ...docData,
              generated: docData.generated.toDate(),
              orderItems: {},
            };
            const orderItemsSnapshot = await this.angularFirestore
              .collection(`${this.autoOrderCollectionPath(storeId)}/${doc.id}/orderItems`)
              .get().toPromise();
            if (!orderItemsSnapshot.empty) {
              // New Storm code for collection
              orderItemsSnapshot.docs.forEach((itemDoc): void => {
                autoOrder.orderItems[itemDoc.id] = itemDoc.data() as AutoOrderItem;
              });
            } else if (docData.items) {
              // Old Storm code for map - handle as a map
              autoOrder.orderItems = {...docData.items};
            }
            aoCollection[doc.id] = autoOrder;
          }),
        );
        subscriber.next(aoCollection);
      };

      if (this.userAccess.stores[storeId] && this.fireAuthService.hasAccess(storeId, {ruleID: 'd.i'}) === true) {
        this.angularFirestore.collection(`${this.autoOrderCollectionPath(storeId)}/`)
          .snapshotChanges()
          .subscribe(
            (docChanges) => {
              const addedDocs = docChanges.filter((change) => change.type === 'added' || change.type === 'modified');
              return process(addedDocs.map((d) => d.payload.doc)); // Added closing parenthesis here
            },
            (error) => {
              subscriber.error(Error(`Error on subscription to ready auto orders (colleagues).\n${error}`));
            },
          );
      } else {
        this.angularFirestore.collection(`${this.autoOrderCollectionPath(storeId)}/`)
          .ref.where('userId', '==', this.userId)
          .onSnapshot(
            (qs) => {
              return process(qs.docs);
            },
            (error) => {
              subscriber.error(Error(`Error on subscription to ready auto orders.\n${error}`));
            },
          );
      }
    });
  }

  // TODO: This function currently checks for items data in the document as a map or if the items are in a collection
  //  (which it should be). Both are checked because the back end has been updated but not on all stores and there might
  //  be old order data that still stores the info in a map.
  subAutoOrdersOld(storeId: string): Observable<AutoOrderCollection> {
    return new Observable<AutoOrderCollection>((observer) => {
      const process = async (docs) => {
        const orders: AutoOrderCollection = {};

        // Process each document in parallel
        await Promise.all(
          docs.map(async (doc) => {
            const data = doc.data();

            // Build the AutoOrder object using spread operator
            const order: AutoOrder = {
              ...data, // Spread the original data into the order object
              generated: data.generated.toDate(),
              orderItems: {}, // Initialize items array
            };

            // Check if the items field is a collection
            const itemsSnapshot = await this.angularFirestore.collection(`${this.autoOrderCollectionPath(storeId)}/${doc.id}/orderItems`).get().toPromise();

            if (!itemsSnapshot.empty) {
              // If items exist as a collection, subscribe to the items collection
              itemsSnapshot.docs.forEach((itemDoc) => {
                order.orderItems[itemDoc.id] = itemDoc.data() as AutoOrderItem; // Add each item to the items map
              });
            } else if (data.items) {
              // Otherwise, handle items as a map (old way) and convert to a map
              order.orderItems = {...data.items};
            }
            orders[doc.id] = order;
          }),
        );
        // Emit the fully processed orders object after all documents are handled
        observer.next(orders);
      };


      if (this.userAccess.stores[storeId] && this.fireAuthService.hasAccess(storeId, {ruleID: 'd.i'}) === true) {
        this.angularFirestore.collection(`${this.autoOrderCollectionPath(storeId)}/`)
          .snapshotChanges().subscribe(
          docChanges => process(docChanges.map(d => d.payload.doc)),
          error => {
            observer.error(Error(`Error on subscription to ready auto orders (colleagues).\n${error}`));
          },
        );
      } else {
        this.angularFirestore.collection(`${this.autoOrderCollectionPath(storeId)}/`)
          .ref.where('userId', '==', this.userId)
          .onSnapshot(
            qs => process(qs.docs),
            error => {
              observer.error(Error(`Error on subscription to ready auto orders.\n${error}`));
            },
          );
      }
    });
  }

  getAutoOrderParts(storeId: string, orderId: string): Promise<null | {
    ready: AutoOrder;
    prepared?: AutoOrderPreparedInfo;
  }> {
    // TODO: I could obscure this to fetch any number > 1 of documents ✪ ω ✪
    return new Promise((resolve) => {
      const result: { ready: AutoOrder; prepared?: AutoOrderPreparedInfo } =
        {} as { ready: AutoOrder; prepared?: AutoOrderPreparedInfo };
      const promises: Promise<void>[] = [];

      promises.push(new Promise(async (resolveInner) => {
        const doc = await this.angularFirestore.doc(
          `/operational/stores_data/${storeId}/data/auto_ordering/ready_ao/orders/${orderId}`,
        ).get().toPromise();
        const data = doc.data();

        if (data) {
          result.ready = data as AutoOrder;
        }
        resolveInner();
      }));
      promises.push(new Promise(async (resolveInner, rejectInner) => {
        const doc = await this.angularFirestore.doc(
          `/operational/stores_data/${storeId}/data/auto_ordering/prepared/orders/${orderId}`,
        ).get().toPromise();
        const data = doc.data();

        if (data) {
          result.prepared = data as AutoOrderPreparedInfo;
        }
        resolveInner();
      }));

      forkJoin(promises).toPromise().then(() => {
        resolve(Object.keys(result).length ? result : null);
      });
    });
  }

  async getAutoOrder(storeId: string, orderId: string): Promise<AutoOrder | null> {
    // TODO: I could obscure this to fetch any number > 1 of documents ✪ ω ✪
    try {
      const doc = await this.angularFirestore.doc(`${this.autoOrderCollectionPath(storeId)}/${orderId}`).get().toPromise();
      return doc.data() as AutoOrder | null;
    } catch (error) {
      error.message = `Error fetching Auto Order "${orderId}"\n${error.message}`;
      throw error;
    }
  }

  addEmptyAutoOrder(storeId: string, supplierId: string, codes: string[]): Promise<void> {
    const d = new Date();
    const orderId = `${this.userId.replace(/\./g, '-')}_${supplierId}_${d.getDate()}-${d.getMonth() + 1}`;
    const data: AutoOrder = {
      userId: this.userId, owned: this.userId, supplierId: supplierId, generated: d, scheduled: 'CREATED',
      orderSettings: null, orderItems: {}, orderId: orderId, status: 'READY',
    };
    codes.forEach(code => data.orderItems[code] = {new: 'ADDED'});
    return this.angularFirestore.doc(`${this.autoOrderCollectionPath(storeId)}/${orderId}`).set(data);
  }

  makeAutoOrderReadyDirty(storeId: string, orderId: string, codes: string[]): Promise<void> {
    const value = {
      mainSupplier: 0,
      outOfStock: 0,
      maxSold: 0,
      netSold: 0,
      qtyDiff: 0,
      maxReturned: 0,
      negativeDays: 0,
      zeroDays: 0,
      new: 'ADDED',
    };
    const update: any[] = [];
    // codes.forEach(code => update.push(new FieldPath('items', code), value));
    codes.forEach((code) => update.push(new FieldPath('orderItems', code), value));
    const doc = this.angularFirestore.firestore.doc(`${this.autoOrderCollectionPath(storeId)}/${orderId}`);
    // this.af.firestore.doc(`/operational/stores_data/${storeId}/data/auto_ordering/ready_ao/orders/${orderId}`);
    return doc.update.apply(doc, update);
  };


  async removeItemsFromOrder(storeId: string, orderId: string, codes: string[]): Promise<void> {
    try {
      const batch = this.angularFirestore.firestore.batch();

      codes.forEach((code: string) => {
        const itemDoc: AngularFirestoreDocument = this.angularFirestore.doc(`${this.autoOrderCollectionPath(storeId)}/${orderId}/orderItems/${code}`);
        batch.delete(itemDoc.ref);
      });

      await batch.commit();
    } catch (error) {
      error.message = `Error removing items from order "${orderId}"\n${error.message}`;
    }
  }

  async setAOQuantities(
    storeId: string, order: AutoOrder, preparedItems: {
      [code: string]: IAutoOrderOrderPreparedInfoDetailsFirestore
    }, total: number,
    status: string, comment?: string, dirtyCodes?: string[],
  ): Promise<void> {
    // suppAccount: string, orderId: string,
    // quantities: { [code: string]: { qty: number; price?: number } }, dirtyCodes: string[],
    // ogUserID: string, total: number, comment?: string): Promise<void> {

    const payload: AutoOrderPreparedInfo = {ts: SERVER_TIMESTAMP(), preparedItems, total};

    if (comment) {
      payload.comment = comment;
    } else if (order?.preparedInfo?.comment) {
      payload.comment = order.preparedInfo.comment;
    }

    // TODO: Determine if dirty codes are still needed at all.
    if (dirtyCodes && dirtyCodes.length) {
      payload.dirty = {};
      dirtyCodes.forEach((code) => (payload.dirty[code] = true));
    }

    try {
      await this.angularFirestore.doc(`${this.autoOrderCollectionPath(storeId)}/${order.orderId}`)
        .update({userId: this.userId, status, preparedInfo: payload});
    } catch (error) {
      throw Error(`Error in setAOQuantities. Changes not updated.\n${error}`);
    }
  }

  async deleteAutoOrder(storeId: string, orderId: string | string[]): Promise<void> {
    const toDelete = typeof orderId === 'string' ? [orderId] : orderId;

    try {
      for (const oID of toDelete) {
        const orderDocRef = this.angularFirestore.doc(`${this.autoOrderCollectionPath(storeId)}/${oID}`).ref;

        // Check if 'orderItems' sub collection exists and delete its documents
        const orderItemsSnapshot = await this.angularFirestore.collection(`${this.autoOrderCollectionPath(storeId)}/${oID}/orderItems`).get().toPromise();

        const batch = this.angularFirestore.firestore.batch();

        if (!orderItemsSnapshot.empty) {
          orderItemsSnapshot.forEach(doc => {
            batch.delete(doc.ref); // Delete each document in 'orderItems' subcollection
          });
        }

        // Delete the order document itself
        batch.delete(orderDocRef);

        // Commit the batch delete
        await batch.commit();
      }
    } catch (e) {
      e.message = `Error deleting Order: \n${e.message}`;
      throw e;
    }
  }

  async submitAutoOrder(
    storeId: string,
    orderIds: string[],
    ordersSupp: { [orderId: string]: string },
    recipients: { [orderId: string]: string[] },
    ignoreHist?: string[]) {
    const batch = this.angularFirestore.firestore.batch();

    for (const orderId of orderIds) {
      const docPath = `${this.autoOrderCollectionPath(storeId)}/${orderId}`;
      batch.update(this.angularFirestore.doc(docPath).ref, {status: 'SUBMITTED', recipients: recipients[orderId]});
    }
    const msgRef = this.angularFirestore.collection(`/operational/stores_data/${storeId}/messages/from_app/`).doc().ref;
    const msg: Message = {
      type: 'AUTO_ORDERS',
      payload: {
        data: orderIds,
      },
      sender: this.userId, timestamp: new Date(),
    };
    batch.set(msgRef, msg);

    // if (ignoreHist && ignoreHist.length) {
    //   const updates = {};
    //
    //   for (const supp of ignoreHist) {
    //     updates[`${supp}.ignore`] = true;
    //   }
    //   // batch.update(
    //   //   // this.af.doc(`/operational/stores_data/${storeId}/data/auto_ordering/basic_history`).ref, updates,
    //   // );
    // }

    if (Object.keys(recipients).length) {
      const update: { [supp: string]: string[] } = {};
      Object.keys(recipients).forEach((orderId) => (update[ordersSupp[orderId]] = recipients[orderId]));
      const ref = this.angularFirestore.doc(path_stores_storeId_settings_ao_supp_emails(storeId)).ref;
      batch.set(ref, update, {merge: true});
    }
    try {
      await batch.commit();
    } catch (e) {
      e.message = 'Submitting AO batch failed.\n' + e.message;
      throw e;
    }
  }

  reattemptAutoOrder(msgID: string, storeId: string, reattemptData: { [orderId: string]: any } | string[]):
    Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const now = new Date();
      const msg: Message = {
        type: 'AUTO_ORDERS',
        payload: {
          data: reattemptData,
        },
        sender: this.userId, timestamp: now,
      };
      const reattemptFieldsUpdates = {};
      const batch = this.angularFirestore.firestore.batch();

      for (const orderId of Object.keys(reattemptData)) {
        reattemptFieldsUpdates[`payload.data.${orderId}.reattempted`] = SERVER_TIMESTAMP();
        const update: any = {status: 'SUBMITTED'};

        if (reattemptData[orderId].pos !== 'AO_EMAIL_FAIL') {
          if (reattemptData[orderId].itemsToIgnore) {
            for (const code of reattemptData[orderId].itemsToIgnore) {
              update[`preparedInfo.quantities.${code}`] = DELETE_FILED();
            }
          }
        }
        batch.update(
          this.angularFirestore.doc(`${this.autoOrderCollectionPath(storeId)}/${orderId}`).ref,
          update,
        );
      }
      batch.update(this.angularFirestore.doc(`users/${this.userId}/messages/${msgID}`).ref, reattemptFieldsUpdates);
      batch.commit()
        .then(() => this.angularFirestore.collection(`/operational/stores_data/${storeId}/messages/from_app/`).add(msg)
          .then((dr) => {
            resolve(dr.id);
          })
          .catch(error => {
            error.message = `AO reattempt to add message to store.\n${error.message}`;
            reject(error);
          }),
        ).catch(error => {
        error.message = `AO reattempt failed on batch updates.\n${error.message}`;
        reject(error);
      });
    });
  }

  requestAutoOrderUnscheduled(storeId: string, orderData: { [supp: string]: AOScheduleElement }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const msg: Message = {
        type: 'AUTO_ORDERS_U_UPDATE', timestamp: new Date(), sender: this.userId, payload: {data: orderData},
      };
      this.angularFirestore.collection(`/operational/stores_data/${storeId}/messages/from_app/`).add(msg)
        .then(doc => {

          resolve();
        })
        .catch(e => {
          e.message = `Error sending unscheduled order to server ${storeId}. ${e.message}`;
          reject(e);
        });
    });
  }

  autoOrderCollectionPath(storeId: string): string {
    return `/operational/stores_data/${storeId}/data/auto_ordering/auto_orders/orders`;
  }

  serverConfigMessage(
    storeId: string, configs: { [config: string]: any }, feature: Feature = 'operational',
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const batch = this.angularFirestore.firestore.batch();
      Object.keys(configs).forEach(config => {
        batch.set(this.angularFirestore.doc(`/stores/${storeId}/configs/${config}`).ref, configs[config], {merge: true});

      });

      const payload = {data: Object.keys(configs)};
      const message: Message = {payload, sender: this.userId, timestamp: new Date(), type: 'SERVER_CONFIG'};

      batch.commit().then(() =>
        this.angularFirestore.collection(`/${feature}/stores_data/${storeId}/messages/from_app/`).add(message).then(r => {

          resolve(r.id);
        }).catch(e => {
          e.message = `Could not send server configuration message: ${e.message}`;
          reject(e);
        }).catch(e => {
          e.message = `Could not update server configurations: ${e.message}`;
          reject(e);
        }),
      );
    });
  }


  /* ________________________________________________ STOCK UPDATES _________________________________________________ */

  reattemptUpdateStock(storeId: string, apiLogID: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const msg: Message = {
        sender: this.userId, timestamp: new Date(), type: 'STOCK_UPDATE', payload: {data: {logID: apiLogID}},
      };
      this.angularFirestore.collection(`/operational/stores_data/${storeId}/messages/from_app/`)
        .add(msg).then(() => {
        resolve();
      }).catch(e => {
        e.message = `Failed to send logID msg to server.\nLogID: ${apiLogID}\n${e.message}`;
        reject(e);
      });
    });
  }

  async queryStockUpdates(
    storeId: string,
    query: { code?: string; minDate?: Date; maxDate?: Date; userId?: string },
  ): Promise<ApiLogObj2[]> {
    const reference = this.angularFirestore.collection(`/operational/stores_data/${storeId}/data/api_events/`, (r) => {
      let ref: CollectionReference = r;


      if (query.minDate) {
        ref = ref.where('executedDate', '>=', query.minDate) as CollectionReference;
      }
      if (query.maxDate) {
        ref = ref.where('executedDate', '<=', query.maxDate) as CollectionReference;
      }
      if (query.userId) {
        ref = ref.where('userId', '==', query.userId) as CollectionReference;
      }
      if (query.code) {
        ref = ref.orderBy(new FieldPath('data', query.code)) as CollectionReference;

      }

      // TODO: NBNB this needs to apply on code queries as well but for now cant as an index is required. Me (Storm)
      //  needs to figure out the damn index thingy on the firebase-functions repo. To do this auto magically
      // ref = ref.orderBy('executedDate', 'desc') as CollectionReference<DocumentData>;
      // return ref.limit(15);
      return ref;
    });

    const results: ApiLogObj2[] = [];
    (await reference.get().toPromise()).forEach((sh) => {
      const data = sh.data() as ApiLogObj2;

      if (data.hasOwnProperty('executedDate')) {
        data.id = sh.id;
        ['creationDate', 'scheduleDate', 'executedDate', 'lastAttempt'].forEach((dateKey) => {
          if (data[dateKey]) {
            data[dateKey] = (data[dateKey] as Timestamp).toDate();
          }
        });
        results.push(data);
      }
    });
    return results;
  }


  stockUpdate(storeId: string, updates: { [code: string]: { o: StockItem; n: StockItem } }, scheduledDate?: Date) {
    return new Promise<string>(async (resolve, reject) => {
      const data: { [code: string]: StockItemUpdateObj } = {};

      const newItems: { [code: string]: StockItem } = {};
      const oldItems: { [code: string]: StockItem } = {};

      Object.keys(updates).forEach(code => {
        data[code] = {o: {}, n: {}};
        // Object.keys(updates[code].o).forEach(k => data[code].o[sItemKeyToInt[k]] = updates[code].o[k]);
        Object.keys(updates[code].n).forEach((k) => {
          if (!updates[code].o.hasOwnProperty(k) || updates[code].n[k] !== updates[code].o[k]) {
            data[code].n[sItemKeyToInt[k]] = updates[code].n[k];
            data[code].o[sItemKeyToInt[k]] = updates[code].o[k];

            if (!newItems[code]) {
              newItems[code] = {} as StockItem;
              oldItems[code] = {} as StockItem;
            }
            newItems[code][k] = updates[code].n[k];
            oldItems[code][k] = updates[code].o[k];

          } else {
            this.alertControl.create({
              header: 'Whoops', subHeader: 'The updates object passed to the firebase service isn\'t as it should be.',
              message: 'Please inform Techodactyl Support this happened as well as whether you were on the NGP page or the Stock ' +
                `page.<br>Your update will still go through but this key, of this item, is excluded:<br><br>${code}: ` +
                `${!updates[code].o.hasOwnProperty(k) ? 'key ' + k + ' not in original object.' : k + ': update == ' +
                  'original value "' + updates[code].o[k] + '"'}`, cssClass: ['custom-alert', 'error'], buttons: ['ok'],
            }).then(ac => ac.present());
          }
        });
      });

      const changeChecks = this.sVChangeCheckConfigs[storeId];
      if (changeChecks) {
        const itemFlags: { [code: string]: StockValuesChangeCheckResult } = {};
        const flaggedCodes = Object.keys(newItems).filter((code) => {
          const flags = StockFunctions.checkStockValues(newItems[code], this.sVChangeCheckConfigs[storeId],
            oldItems[code]);
          if (flags) {
            itemFlags[code] = flags;
            return true;
          }
          return false;
        });

        if (flaggedCodes.length) {
          const ac = await this.modalController.create({
            component: StockValChangeFlagsComponent, componentProps: {codes: flaggedCodes, itemFlags},
          });
          await ac.present();
          const result = await ac.onDidDismiss();
          if (result.role === 'change') {
            for (const code of Object.keys(result.data)) {
              for (const key of Object.keys(result.data[code])) {
                newItems[code][key] = result.data[code][key];
                data[code].n[sItemKeyToInt[key]] = result.data[code][key];
              }
            }
          }
        }
      }

      const apiLogObj: ApiLogObj2 = {
        creationDate: new Date(), data, type: 'STOCK_UPDATE',
        userId: this.userId,
      };
      const msg: Message = {
        sender: this.userId, timestamp: new Date(), type: 'STOCK_UPDATE', payload: {data: {}},
      };

      if (scheduledDate && scheduledDate > new Date()) {
        msg.payload.data.scheduledDate = scheduledDate;
      }

      this.angularFirestore.firestore.runTransaction(async trans => {
        const docRef = this.angularFirestore.collection(`/operational/stores_data/${storeId}/data/api_events/`).doc().ref;
        msg.payload.data.logID = docRef.id;
        trans.set(docRef, apiLogObj);
        trans.set(this.angularFirestore.collection(`/operational/stores_data/${storeId}/messages/from_app/`).doc().ref, msg);
        return docRef.id;
      }).then((docID: string): void => {
        resolve(docID);
      })
        .catch(error => {
          error.message = 'Failed to run stock update transaction\n' + error.message;
          console.error(error.message);
          reject(error);
        });

      // this.af.collection(`/operational/stores_data/${storeId}/data/api_events/`)
      //   .add(apiLogObj).then(docRef => {
      //     msg.payload.data.logID = docRef.id;
      //     this.af.collection(`/operational/stores_data/${storeId}/messages/from_app/`)
      //       .add(msg).then(doc => resolve(doc.id)).catch(e =>
      //       reject(`Failed to send logID msg to server.\nLogID: ${docRef.id}\n${e}`));})
      //   .catch(e => reject('Filed to create stock update api log.\n' + e));
    });
  }

  /* ---------------------------------------------------------------------------------------------------------------- */
  /* .                                                TESTING STUFF                                                 . */

  /* ---------------------------------------------------------------------------------------------------------------- */

  testingHider(onChange: (show: boolean) => void, additionalUIDs?: string[]): Subscription {
    let uids = ['claydenburger@gmail.com', 'baileyhassall@gmail.com'];
    uids = additionalUIDs ? uids.concat(additionalUIDs) : uids;
    uids = [...new Set(uids)];
    // TODO: shouldn't this be a merge map or something?
    return this.userObj.subscribe((uo) => {
        onChange(uo && uids.includes(uo.id));
      },
    );
  }

  news(indexDate: Date = null, limit: number = 5, direction: 'startAfter' | 'endBefore' = 'startAfter'):
    Observable<FormPost[]> {
    interface FormPostPollRaw extends Omit<FormPostPoll, 'expire'> {
      expire?: Timestamp;
    }

    interface FormPostRaw extends Omit<FormPost, 'ts' | 'poll'> {
      ts: Timestamp;
      poll?: FormPostPollRaw;
    }


    return this.angularFirestore.collection(
      '/community_form',
      (ref) => {
        const r = ref.orderBy('ts', 'desc');

        if (direction === 'startAfter') {
          return indexDate ? r.startAfter(indexDate).limit(limit) : r.limit(limit);
        }
        return indexDate ? r.endBefore(indexDate).limit(limit) : r.limit(limit);
      },
    ).valueChanges({idField: 'id'}).pipe(mergeMap((vc) => {
      const data = vc as FormPostRaw[];
      return [data.map((rfp) => {
        const fp = (rfp as any) as FormPost;
        fp.ts = rfp.ts.toDate();

        if (fp.poll) {
          if (fp.poll.expire) {
            fp.poll.expire = (rfp.poll.expire).toDate();
          } else {
            fp.poll.expire = dateDelta(fp.ts, {days: 1});
          }
        }
        return fp;
      })];
    }));
  }

  oldestFormPost(): Observable<Date> {
    return this.angularFirestore.collection('/community_form', (ref) => ref.orderBy('ts').limit(1)).valueChanges({idField: 'id'})
      .pipe(mergeMap((vc): [Date | null] => {
        if (vc.length) {
          return [(vc[0] as { id: string; ts: Timestamp }).ts.toDate()];
          // return [[vc[0]] as FormPost[]];
        }
        return [null];
      }));
  }

  formPost(fp: FormPost): Promise<string> {
    return new Promise<string>((resolve, reject) =>
      this.angularFirestore.collection('/community_form').add(fp)
        .then((dr) => {
          resolve(dr.id);
        })
        .catch((e) => {
          e.message = 'Failed to post to Community From' + e.message;
          reject(e);
        }),
    );
  }

  async getFormPollVote(formPostID: string): Promise<(string | number)[]> {
    let doc;

    try {
      doc = await this.angularFirestore.doc(`/community_form/${formPostID}/form_poll_votes/${this.userId}`).get().toPromise();
    } catch (e) {
      e.message = 'getFormPollVote Failed: ' + e.message;
      throw e;
    }
    const data = doc ? doc.data() : null;
    return data ? (data as { vote: (string | number)[] }).vote : null;
  }

  async formPollVote(vote: (string | number)[], formPostID: string): Promise<void> {


    try {
      if (vote.length > 0) {
        await this.angularFirestore.doc(`/community_form/${formPostID}/form_poll_votes/${this.userId}`).set({vote});
      } else {
        await this.angularFirestore.doc(`/community_form/${formPostID}/form_poll_votes/${this.userId}`).delete();
      }
    } catch (e) {
      e.message = 'formPollVote Failed: ' + e.message;
      throw e;
    }
  }

  async getCommunityFormImg(imgName: string, postID: string) {
    try {
      return await this.angularFireStorage.ref(`community_form/${postID}/${imgName}`).getDownloadURL().toPromise();
    } catch (error) {
      error.message = `Error fetching image for community form, postID="${postID}", imgName="${imgName}"` +
        error.message;
      throw error;
    }
  }

  bulkPermissionTest(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const alpha = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'X',
        'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
      const update = {};

      for (let i = 0; i < 25; i++) {
        for (let j = 0; j < 25; j++) {
          update[alpha[i] + alpha[j]] = {v1: +(Math.random().toFixed(2)), v2: +(Math.random().toFixed(2))};

          if (Object.keys(update).length === 500) {
            break;
          }
        }
        if (Object.keys(update).length === 500) {
          break;
        }
      }
      const batch = this.angularFirestore.firestore.batch();
      Object.keys(update).forEach(key => batch.set(
        this.angularFirestore.doc(`/test/bulk_rules_test/bulk_rules_test/${key}`).ref, update[key],
      ));

      batch.commit().then(() => {
        resolve();
      }).catch(e => {
        reject(e);
      });
    });
  }

  /* ----------------------------------------------- FIREBASE ALERTS ------------------------------------------------ */

  private checkForForcedReload(d: any) {
    if (this.userObjSubject.getValue() !== null && d.hasOwnProperty('forceReload')) {
      this.forceReloadService.register(d.forceReload);
      this.angularFirestore.doc(`users/${this.userId}`).update({forceReload: DELETE_FILED()}).then(() => {

      });
    }
  }

}
