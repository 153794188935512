<div class="card">
  <div class="card-header">
    <h2>Tag Manager</h2>
  </div>

  <div class="card-body">
    <div class="input-section">
      <label for="tagInput">Add New Tag</label>
      <input
        (keyup)="formatTag()"
        [(ngModel)]="newTag"
        id="tagInput"
        placeholder="Enter a tag (e.g., example_tag)"
        type="text"
      />
      <button
        (click)="addTag()"
        [disabled]="!newTag || isTagInvalid"
        class="add-button"
        type="button"
      >
        Add Tag
      </button>

      <div *ngIf="isTagInvalid && newTag.length > 0" class="error-message">
        {{ tagErrorMessage }}
      </div>
    </div>

    <div class="output-section">
      <h3>Existing Tags</h3>
      <ul>
        <li *ngFor="let tag of tags; trackBy: trackByFunction">
          {{ tag.name }}
          <button
            (click)="deleteTag(tag.name)"
            *ngIf="!tag.global; else global"
            class="delete-button"
            type="button"
          >
            Delete
          </button>
          <ng-template #global>
            <span class="global-tag">
              Global
            </span>
          </ng-template>
        </li>
      </ul>
    </div>
  </div>
</div>
