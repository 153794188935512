<div>
  <ng-container *ngIf="selectedValues?.length > 0">
    <ion-chip
      [matTooltip]="selectedValues ? formatSelectedValues(selectedValues) : null"
      matTooltipClass="tooltip-list">
      {{ selectedValuesLabel }}
    </ion-chip>
    <app-icon-backspace-outline
      (click)="onUndoChangesClick()"
      *ngIf="params.data.isEdited&& !params.data.disabled"
      [tooltip]="'Previous Value = ' +params.data.originalValue[params.colDef.field].value">
    </app-icon-backspace-outline>
  </ng-container>
</div>

